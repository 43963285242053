import { publicClientApplication } from "./Auth";
import { jwtDecode } from "jwt-decode";
import { interaction } from "../Tracking";

export function getAggField(metric: {
  aggregator: string;
  scope: string;
  field: string;
}): string {
  let heading;
  let heading2;
  let heading3;

  if (metric.aggregator === "sum" || metric.aggregator === "count") {
    heading = "Total";
  } else {
    heading = "Unique";
  }

  if (metric.scope === "visitor") {
    heading3 = "Visitors";
  } else if (metric.scope === "event") {
    heading3 = "Conversions";
  }

  if (metric.field === "revenue") {
    heading2 = "Revenue";
  } else if (metric.field === "value") {
    heading2 = "Value";
  } else {
    heading2 = "Conversions";
  }
  return heading + " " + heading2 + " " + heading3;
}

export const evenDistributedAllocataion = (nrOfVariations: number) => {
  let evenPerVariation = Number((100 / nrOfVariations).toFixed(2));

  let arr = [];
  let full = 100;

  for (let i = 1; i <= nrOfVariations; i++) {
    full = full - evenPerVariation;
    if (i === nrOfVariations) {
      if (full !== 0) {
        evenPerVariation = Number(
          (evenPerVariation + Number(full.toFixed(2))).toFixed(2)
        );
      }
    }
    arr.push(Number(evenPerVariation));
  }
  return arr;
};

export const getDefaultType = () => {
  const type = localStorage.getItem("fabulousType");
  return type || "0";
};

export const setDefaultType = (type: string) => {
  localStorage.setItem("fabulousType", type);
};

export const getDefaultTeam = () => {
  const storedId = localStorage.getItem("fabulousTeamId");
  return storedId ? JSON.parse(storedId) : 0;
};

export const setDefaultTeam = (teamId: Number) => {
  localStorage.setItem("fabulousTeamId", JSON.stringify(teamId));
};

export const setDefaultBrand = (brandId: Number) => {
  localStorage.setItem("fabulousBrandId", JSON.stringify(brandId));
};

export const getDefaultBrand = () => {
  const storedId = localStorage.getItem("fabulousBrandId");
  return storedId ? JSON.parse(storedId) : 0;
};

export const setDefaultState = (stateId: Number) => {
  localStorage.setItem("fabulousStateId", JSON.stringify(stateId));
};

export const getDefaultState = () => {
  const storedId = localStorage.getItem("fabulousStateId");
  return storedId ? JSON.parse(storedId) : -1;
};

export const getDefaultRegion = () => {
  const storedId = localStorage.getItem("fabulousUserLatestRegion");
  return storedId ? storedId : "0";
};

export const setDefaultRegion = (region_key: string) => {
  localStorage.setItem("fabulousUserLatestRegion", region_key);
};
export const getDefaultHasLive = () => {
  const check = localStorage.getItem("fabulousUserHasLive");
  return check ? JSON.parse(check) : false;
};

export const setDefaultHasLive = (check: boolean) => {
  localStorage.setItem("fabulousUserHasLive", JSON.stringify(check));
};

export const validToken = (token: string) => {
  try {
    const decoded = jwtDecode(token);
    const now = Math.round(Date.now() / 1000);
    if (decoded.exp && decoded.exp > now) {
      return true;
    } else {
      sessionStorage.setItem("fabulousTokenExpired", JSON.stringify(true));
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const redirectLogin = () => {
  const account = publicClientApplication.getAllAccounts()[0];
  const accessTokenRequest = {
    scopes: ["openid"],
    account: account,
  };
  publicClientApplication.acquireTokenRedirect(accessTokenRequest);
};

export const signOut = () => {
  const account = publicClientApplication.getAllAccounts()[0];
  publicClientApplication.logoutRedirect({
    postLogoutRedirectUri: "/",
    account: account,
  });
};

export const getRedirectUri = () => {
  const { protocol, hostname, port } = window.location;
  return `${protocol}//${hostname}${port ? ":" + port : ""}`;
};

export const dashboardLink = (e: any, navigate: any, url: string) => {
  if (e.metaKey) {
    window.open(url);
  } else if (e.shiftKey) {
    window.open(url, "_blank");
  } else {
    navigate(url);
  }
};

export const formatAudienceMatchType = (matchtype: any) => {
  let out;
  switch (matchtype) {
    case "eq":
      out = "is";
      break;
    case "ne":
      out = "is not";
      break;
    case "sub":
      out = "contains";
      break;
    case "nosub":
      out = "excludes";
      break;
    case "gt":
      out = ">";
      break;
    case "gte":
      out = ">=";
      break;
    case "lt":
      out = "<";
      break;
    case "lte":
      out = "<=";
      break;
    default:
      break;
  }
  return `${out}`;
};

export const isTargetingApps = (ruleData: any) => {
  const touchpointValues = ruleData.attributes
    .filter((audience: any) => audience.attribute === "touchpoint")
    .flatMap((audience: any) => audience.values);
  return (
    touchpointValues.includes("ios") || touchpointValues.includes("android")
  );
};

export const handleClickLearnMore = (e: any) => {
  interaction("learn_more", [
    { name: "learn_more", stringValue: e.target.href },
  ]);
};

export const handleClickMore = (e: any) => {
  interaction("more");
};
export const getRuleType = (type: string) => {
  const ruleTypes = {
    mab: "Multi-armed bandit",
    rollout: "Rollout",
    abtest: "A/B-Test",
    ranking_bandit: "Ranking Bandit",
  };
  return ruleTypes[type as keyof typeof ruleTypes];
};
