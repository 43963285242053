import { enqueueSnackbar } from "notistack";
import { RuleProps, IVariation } from "../types/Types";

type ValidationRule = (rule: RuleProps) => string | null;

interface TestTypeValidation {
  type: string;
  validations: ValidationRule[];
}

const validateVariablesDiffer = (rule: RuleProps): string | null => {
  return rule.variations[1].variables.length > 0 &&
    getDuplicateVariables(rule.variations) !== null
    ? "Variables must differ between variations"
    : null;
};

const validateUniqueNames = (rule: RuleProps): string | null => {
  return getDuplicates(rule.variations, "name") !== null
    ? "Variation name must be unique"
    : null;
};

const validateNoDefaultNames = (rule: RuleProps): string | null => {
  return getHasDefaultNames(rule)
    ? "Placeholder variation names, 'Variation #N' must be changed to something more descriptive"
    : null;
};

const validateNonEmptyNames = (rule: RuleProps): string | null => {
  return getHasEmptyNames(rule)
    ? "Variation names must be at least 5 characters long and start with a letter or digit"
    : null;
};

const validateLocale = (rule: RuleProps): string | null => {
  return !rule.attributes.some((e) => e.attribute === "locale")
    ? "At least one audience with the locale attribute"
    : null;
};

const validateTouchpoint = (rule: RuleProps): string | null => {
  return !rule.attributes.some((e) => e.attribute === "touchpoint")
    ? "At least one audience with the touchpoint attribute"
    : null;
};

const validateMinVariations = (rule: RuleProps): string | null => {
  return rule.type !== "rollout" && rule.variations.length < 2
    ? "At least two variations"
    : null;
};

const validateSuccessMetrics = (rule: RuleProps): string | null => {
  return rule.type !== "rollout" && !rule.metrics.some((e) => e.is_success)
    ? "At least one success metric"
    : null;
};

const validateHypothesis = (rule: RuleProps): string | null => {
  return rule.hypothesis.includes("state the change on the website/app")
    ? "A valid hypothesis"
    : null;
};

const validateOwnership = (rule: RuleProps): string | null => {
  if (!rule.meta || rule.meta.version < 1 || rule.region_key) {
    return null;
  }

  const errors: string[] = [];
  if (!rule.owner || rule.owner.length < 3) {
    errors.push("Name of the owner");
  }
  if (!rule.developer || rule.developer.length < 3) {
    errors.push("Name of the developer");
  }

  return errors.length > 0 ? errors.join(" and ") : null;
};

const validatePlannedDates = (rule: RuleProps): string | null => {
  if (
    !rule.meta ||
    rule.meta.version < 1 ||
    rule.region_key ||
    rule.type === "rollout"
  ) {
    return null;
  }

  const errors: string[] = [];
  if (!rule.planned_start_date) {
    errors.push("Planned start date");
  }
  if (!rule.planned_end_date) {
    errors.push("Planned end date");
  }

  return errors.length > 0 ? errors.join(" and ") : null;
};

const TEST_TYPE_VALIDATIONS: TestTypeValidation[] = [
  {
    type: "ab",
    validations: [validateVariablesDiffer, validateNoDefaultNames],
  },
  {
    type: "*",
    validations: [
      validateUniqueNames,
      validateNonEmptyNames,
      validateLocale,
      validateTouchpoint,
      validateMinVariations,
      validateSuccessMetrics,
      validateHypothesis,
      validateOwnership,
      validatePlannedDates,
    ],
  },
];

export const isLiveValid = (ruleData: RuleProps, state: number): boolean => {
  // Only validate for states 20 and 30
  if (state !== 20 && state !== 30) {
    return true;
  }

  const validatedRules = validateRules(ruleData);
  if (validatedRules === null) {
    return true;
  }

  const messages = Object.values(validatedRules);
  const output = "The following is missing: \r\n• " + messages.join("\r\n• ");
  enqueueSnackbar(output, {
    variant: "error",
    className: "snackbar-state-error",
  });

  return false;
};

export const validateRules = (
  rule: RuleProps
): Record<string, string> | null => {
  const errors: Record<string, string> = {};

  const applicableValidations = TEST_TYPE_VALIDATIONS.filter(
    (validation) => validation.type === "*" || validation.type === rule.type
  );

  applicableValidations.forEach((testType) => {
    testType.validations.forEach((validation) => {
      const error = validation(rule);
      if (error) {
        errors[validation.name] = error;
      }
    });
  });

  return Object.keys(errors).length > 0 ? errors : null;
};

export const getHasEmptyNames = (rule: RuleProps): boolean => {
  const disallowedVariationsNamePattern = /^(\s+.*|\S{0,4})$/;
  const res = rule.variations.some((variation: IVariation) => {
    return disallowedVariationsNamePattern.test(variation.name);
  });

  return res;
};

export const getHasDefaultNames = (rule: RuleProps): boolean => {
  const disallowedVariationsNamePattern = /Variation #\d/i;
  const res = rule.variations.some((variation: IVariation) => {
    return disallowedVariationsNamePattern.test(variation.name);
  });

  return res;
};

export const getDuplicates = <T>(arr: T[], key: keyof T): T[] | null => {
  const map: Record<string, boolean> = {};
  const duplicates: T[] = [];

  if (!Array.isArray(arr)) {
    return null;
  }

  arr.forEach((item) => {
    const keyValue = item[key] as unknown as string;
    if (map[keyValue]) {
      duplicates.push(item);
    } else {
      map[keyValue] = true;
    }
  });

  return duplicates.length === 0 ? null : duplicates;
};

export const getDuplicateVariables = (
  arr: IVariation[]
): IVariation[] | null => {
  const map: Record<string, boolean> = {};
  const duplicates: IVariation[] = [];

  if (!Array.isArray(arr)) {
    return null;
  }

  arr.forEach((item) => {
    if (item.name !== "Control") {
      const variablesString = JSON.stringify(item.variables);
      if (map[variablesString]) {
        duplicates.push(item);
      } else {
        map[variablesString] = true;
      }
    }
  });

  return duplicates.length === 0 ? null : duplicates;
};
