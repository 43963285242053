import React, { useEffect } from "react";
import { Alert, Link, IconButton, Typography } from "@mui/material";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import { useRankingBandit } from "./context/RankingBanditContext";

export const RankingBanditInfo: React.FC = () => {
  const { dismissedRankingBanditInfo, setDismissedRankingBanditInfo } =
    useRankingBandit();

  useEffect(() => {
    const isDismissed =
      localStorage.getItem("dismissedRankingBanditInfo") === "true";
    setDismissedRankingBanditInfo(isDismissed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (dismissedRankingBanditInfo) {
    return (
      <Typography sx={{ mb: 1, width: "75%", position: "relative" }}>
        <IconButton
          onClick={() => {
            localStorage.removeItem("dismissedRankingBanditInfo");
            setDismissedRankingBanditInfo(false);
          }}
        >
          <InfoOutlined color="primary" />
        </IconButton>
      </Typography>
    );
  }

  return (
    <Alert
      severity="info"
      sx={{ mb: 3, width: "100%" }}
      onClose={() => {
        localStorage.setItem("dismissedRankingBanditInfo", "true");
        setDismissedRankingBanditInfo(true);
      }}
    >
      To create the Ranking Bandit:
      <ul style={{ marginTop: "14px" }}>
        <li>Add one or more variants for each variable.</li>
        <li>
          Select the combinations (Layouts) you want to include in the Ranking
          Bandit test. <br />
          <em style={{ fontSize: "12px", marginTop: "14px" }}>
            (Pro tip: You can expand the Layouts table for simpler editing.)
          </em>
        </li>
        <li>Update the test settings.</li>
      </ul>
      The resulting Bandit will contain all variations of the added values.
      <br />
      <em style={{ fontSize: "12px", marginTop: "16px" }}>
        After test is live, you can not change the values of the added values or
        edit variations.
      </em>
      <br />
      <br />
      <Link
        sx={{ textDecoration: "underline", display: "block" }}
        href=""
        target="_blank"
      >
        More info
      </Link>
    </Alert>
  );
};
