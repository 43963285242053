import React, { createContext, useContext, useState, useEffect } from "react";
import { VariableValues } from "../types";
import { evenDistributedAllocataion } from "../../helpers/Helpers";

interface RankingBanditContextType {
  variableValues: VariableValues;
  flagVariables: any[];
  editDisabled: boolean;
  stateStatus: any;
  ruleData: any;
  setRuleData: (data: any) => void;
  handleAddValue: (varKey: string) => void;
  handleValueChange: (varKey: string, valueId: string, newValue: any) => void;
  handleDeleteValue: (varKey: string, valueId: string) => void;
  addNewVariations: () => void;
  dismissedRankingBanditInfo: boolean;
  setDismissedRankingBanditInfo: (value: boolean) => void;
  flagFetched: boolean;
  getRankingBanditCombinations: (variableValues: VariableValues) => string[][];
  getNewRanges: (sliderAllocation: number, updatedVariations: any[]) => any[];
  sliderAllocation: number;
  combinations: any[];
  getCombinationsFromVariations: (variations: any[]) => string[][];
  selectedCombinations: number[];
  toggleCombinationSelection: (index: number) => void;
}

const RankingBanditContext = createContext<
  RankingBanditContextType | undefined
>(undefined);

export const RankingBanditProvider: React.FC<{
  children: React.ReactNode;
  flagVariables: any[];
  stateStatus: any;
  ruleData: any;
  setRuleData: (data: any) => void;
  addNewVariations: () => void;
  flagFetched: boolean;
  getNewRanges: (sliderAllocation: number, updatedVariations: any[]) => any[];
  sliderAllocation: number;
}> = ({
  children,
  flagVariables,
  stateStatus,
  ruleData,
  setRuleData,
  addNewVariations,
  flagFetched,
  getNewRanges,
  sliderAllocation,
}) => {
  const [variableValues, setVariableValues] = useState<VariableValues>({});
  const [dismissedRankingBanditInfo, setDismissedRankingBanditInfo] =
    useState<boolean>(false);
  const [combinations, setCombinations] = useState<any[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedCombinations, setSelectedCombinations] = useState<number[]>(
    []
  );
  const [editDisabled, setEditDisabled] = useState(false);

  useEffect(() => {
    if (!isInitialized && flagVariables.length > 0 && ruleData?.variations) {
      const initialValues = flagVariables.reduce(
        (acc: VariableValues, variable: any) => {
          if (ruleData?.variations?.length > 0) {
            const uniqueValues = new Set(
              ruleData.variations.map((variation: any) => {
                const varValue = variation.variables.find(
                  (v: any) => v.id === variable.id
                );
                return varValue?.value || variable.default_value;
              })
            );
            acc[variable.var_key] = Array.from(uniqueValues).map((value) => ({
              id: crypto.randomUUID(),
              value: value,
            }));
          } else {
            acc[variable.var_key] = [];
          }
          return acc;
        },
        {} as VariableValues
      );

      const initialCombinations = getRankingBanditCombinations(initialValues);

      const initialSelectedIndices =
        ruleData.variations?.map((_: any, index: number) => index) || [];
      setSelectedCombinations(initialSelectedIndices);

      setVariableValues(initialValues);
      setCombinations(initialCombinations);
      setIsInitialized(true);
    }
    if (ruleData?.state >= 30) {
      setEditDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flagVariables, ruleData, isInitialized]);

  const handleAddValue = (varKey: string) => {
    setVariableValues((prev) => ({
      ...prev,
      [varKey]: [
        ...(prev[varKey] || []),
        { id: crypto.randomUUID(), value: "" },
      ],
    }));
  };

  const handleValueChange = (
    varKey: string,
    valueId: string,
    newValue: any
  ) => {
    setVariableValues((prev) => {
      const newValues = {
        ...prev,
        [varKey]: prev[varKey].map((v) =>
          v.id === valueId ? { ...v, value: newValue } : v
        ),
      };

      const newCombinations = getRankingBanditCombinations(newValues);
      setCombinations(newCombinations);

      // Filter out selected combinations that are no longer valid
      const validSelectedCombinations = selectedCombinations.filter(
        (index) => index < newCombinations.length
      );

      // Only proceed if we have valid selected combinations
      if (validSelectedCombinations.length > 0) {
        const trafficArr = evenDistributedAllocataion(
          validSelectedCombinations.length
        );
        const variationsFromCombinations = validSelectedCombinations.map(
          (selectedIndex, index) => ({
            id: index + 1,
            name: `Variation #${index + 1}`,
            traffic: trafficArr[index],
            variables: flagVariables.map((variable) => ({
              id: variable.id,
              value:
                newCombinations[selectedIndex]?.[
                  flagVariables.findIndex((v) => v.var_key === variable.var_key)
                ] || variable.default_value,
            })),
          })
        );

        setRuleData((prevState: any) => ({
          ...prevState,
          variations: variationsFromCombinations,
          distribution: getNewRanges(
            sliderAllocation,
            variationsFromCombinations
          ),
        }));
      } else {
        // If no valid combinations exist, reset variations
        setRuleData((prevState: any) => ({
          ...prevState,
          variations: [],
          distribution: [],
        }));
      }

      return newValues;
    });
  };

  const handleDeleteValue = (varKey: string, valueId: string) => {
    setVariableValues((prev) => {
      if (prev[varKey].length <= 1) {
        return prev;
      }

      const newValues = {
        ...prev,
        [varKey]: prev[varKey].filter((v) => v.id !== valueId),
      };

      const newCombinations = getRankingBanditCombinations(newValues);
      if (newCombinations.length < 1) {
        setRuleData((prevState: any) => ({
          ...prevState,
          variations: [],
          distribution: [],
        }));
        return newValues;
      }
      setCombinations(newCombinations);

      const noOfVariations = newCombinations.length;
      const trafficArr = evenDistributedAllocataion(noOfVariations);

      const activeVarKeys = flagVariables
        .map((v) => v.var_key)
        .filter((key) => newValues[key]?.length > 0); // Use newValues here

      const variationsFromCombinations = newCombinations.map(
        (combination, index) => ({
          id: index + 1,
          name: `Variation #${index + 1}`,
          traffic: trafficArr[index],
          variables: flagVariables.map((variable) => {
            const varIndex = activeVarKeys.indexOf(variable.var_key);
            return {
              id: variable.id,
              value:
                varIndex >= 0 ? combination[varIndex] : variable.default_value,
            };
          }),
        })
      );

      setRuleData((prevState: any) => ({
        ...prevState,
        variations: variationsFromCombinations,
        distribution: getNewRanges(
          sliderAllocation,
          variationsFromCombinations
        ),
      }));

      return newValues;
    });
  };

  const getRankingBanditCombinations = (
    variableValues: VariableValues
  ): string[][] => {
    const orderedValues: any[][] = [];
    const orderedVarKeys: string[] = [];

    flagVariables.forEach((variable) => {
      const values = variableValues[variable.var_key];
      if (values && values.length > 0) {
        const nonEmptyValues = values
          .map((v: { value: any }) => v.value)
          .filter((value: string) => value !== "");
        const hasEmpty = values.some((v: { value: any }) => v.value === "");

        if (nonEmptyValues.length > 0 || hasEmpty) {
          orderedVarKeys.push(variable.var_key);
          orderedValues.push(
            hasEmpty ? [...nonEmptyValues, ""] : nonEmptyValues
          );
        }
      }
    });

    if (orderedValues.length === 0) return [];

    const cartesian = (arr: any[][]): any[][] => {
      return arr.reduce(
        (a, b) => a.flatMap((x) => b.map((y) => [...x, y])),
        [[]]
      );
    };

    return cartesian(orderedValues);
  };

  const getCombinationsFromVariations = (variations: any[]): string[][] => {
    if (!variations || variations.length === 0) return [];

    const combinationsFromVariations = variations.map((variation) =>
      variation.variables.map((variable: any) => variable.value)
    );

    setCombinations(combinationsFromVariations);

    const newVariableValues = { ...variableValues };
    flagVariables.forEach((variable: any, index: number) => {
      newVariableValues[variable.var_key] = combinationsFromVariations.map(
        (combo) => ({
          id: crypto.randomUUID(),
          value: combo[index],
        })
      );
    });
    setVariableValues(newVariableValues);

    return combinationsFromVariations;
  };

  const toggleCombinationSelection = (index: number) => {
    setSelectedCombinations((prev) => {
      // If trying to deselect and only one is selected, prevent the action
      if (prev.includes(index) && prev.length <= 1) {
        return prev;
      }

      const newSelected = prev.includes(index)
        ? prev.filter((i) => i !== index)
        : [...prev, index];

      // Update variations based on selected combinations
      const trafficArr = evenDistributedAllocataion(newSelected.length);
      const variationsFromSelected = newSelected.map(
        (selectedIndex, index) => ({
          id: index + 1,
          name: `RankingBandit Variation #${index + 1}`,
          traffic: trafficArr[index],
          variables: flagVariables.map((variable: any, varIndex: number) => ({
            id: variable.id,
            value:
              combinations[selectedIndex][varIndex] || variable.default_value,
          })),
        })
      );

      setRuleData((prevState: any) => ({
        ...prevState,
        variations: variationsFromSelected,
        distribution: getNewRanges(sliderAllocation, variationsFromSelected),
      }));

      return newSelected;
    });
  };

  return (
    <RankingBanditContext.Provider
      value={{
        variableValues,
        flagVariables,
        editDisabled,
        stateStatus,
        ruleData,
        setRuleData,
        handleAddValue,
        handleValueChange,
        handleDeleteValue,
        addNewVariations,
        dismissedRankingBanditInfo,
        setDismissedRankingBanditInfo,
        flagFetched,
        getRankingBanditCombinations,
        getNewRanges,
        sliderAllocation,
        getCombinationsFromVariations,
        combinations,
        selectedCombinations,
        toggleCombinationSelection,
      }}
    >
      {children}
    </RankingBanditContext.Provider>
  );
};

export const useRankingBandit = () => {
  const context = useContext(RankingBanditContext);
  if (context === undefined) {
    throw new Error(
      "useRankingBandit must be used within a RankingBanditProvider"
    );
  }
  return context;
};
