import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  LinearProgress,
  Checkbox,
  IconButton,
  Modal,
  Backdrop,
} from "@mui/material";
import { Box } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";

import { isDuplicateValue } from "./utils/rankingBanditValidates";
import { useRankingBandit } from "./context/RankingBanditContext";

export const VarationsRankingBanditTable: React.FC = () => {
  const {
    variableValues,
    flagVariables,
    combinations,
    selectedCombinations,
    toggleCombinationSelection,
    editDisabled,
  } = useRankingBandit();
  const [isLoading, setIsLoading] = useState(false);
  const [isReordering, setIsReordering] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const sortedCombinations = React.useMemo(() => {
    return combinations
      .map((combination, index) => ({
        combination,
        index,
        isSelected: selectedCombinations.includes(index),
      }))
      .sort((a, b) =>
        a.isSelected === b.isSelected ? 0 : a.isSelected ? -1 : 1
      )
      .map((item) => item.combination);
  }, [combinations, selectedCombinations]);

  const handleToggleCombination = (index: number) => {
    setIsReordering(true);
    toggleCombinationSelection(index);
    setTimeout(() => setIsReordering(false), 300);
  };

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 400);

    return () => {
      clearTimeout(timer);
    };
  }, [combinations]);

  const tableContent = (
    <>
      {(isLoading || isReordering) && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "3px",
          }}
        />
      )}
      <Box
        sx={{
          overflowX: "auto",
          opacity: isReordering ? 0.7 : 1,
          transition: "opacity 0.3s ease",
        }}
      >
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #ddd",
                  borderRight: "1px solid #ddd",
                  textAlign: "left",
                  width: "40px",
                  fontSize: "14px",
                }}
              >
                Enabled
              </th>
              <th
                style={{
                  padding: "8px",
                  borderBottom: "1px solid #ddd",
                  borderRight: "1px solid #ddd",
                  textAlign: "left",
                  fontSize: "14px",
                }}
              >
                Id
              </th>

              {flagVariables.map((variable) => (
                <th
                  key={variable.id}
                  style={{
                    padding: "8px",
                    borderBottom: "1px solid #ddd",
                    borderRight: "1px solid #ddd",
                    fontSize: "14px",
                    textAlign: "left",
                  }}
                >
                  {variable.var_key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedCombinations.map((combination, displayIndex) => {
              const originalIndex = combinations.indexOf(combination);
              const isSelected = selectedCombinations.includes(originalIndex);
              return (
                <tr
                  key={displayIndex}
                  style={{
                    backgroundColor: isSelected
                      ? "rgba(25, 118, 210, 0.08)"
                      : "inherit",
                  }}
                >
                  <td
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #ddd",
                      borderRight: "1px solid #ddd",
                      textAlign: "left",
                      width: "35px",
                    }}
                  >
                    <Checkbox
                      checked={isSelected}
                      onChange={() => handleToggleCombination(originalIndex)}
                      disabled={editDisabled || isReordering}
                    />
                  </td>

                  <td
                    style={{
                      padding: "8px",
                      borderBottom: "1px solid #ddd",
                      borderRight: "1px solid #ddd",
                      textAlign: "left",
                      width: "35px",
                    }}
                  >
                    {displayIndex + 1}
                  </td>
                  {combination.map((value: string, valueIndex: number) => (
                    <td
                      key={valueIndex}
                      style={{
                        padding: "8px",
                        borderBottom: "1px solid #ddd",
                        borderRight: "1px solid #ddd",
                        fontSize: "16px",
                        color: isDuplicateValue(
                          flagVariables[valueIndex].var_key,
                          value,
                          variableValues
                        )
                          ? "red"
                          : "inherit",
                      }}
                    >
                      {value ? (
                        <Typography variant="body2">{value}</Typography>
                      ) : (
                        <Typography variant="body2" sx={{ color: "#ddd" }}>
                          [empty]
                        </Typography>
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </>
  );

  return (
    <>
      <Paper sx={{ p: 2, width: "100%", position: "relative" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
          <IconButton onClick={() => setIsExpanded(!isExpanded)}>
            {isExpanded ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Box>
        {!isExpanded && tableContent}
      </Paper>

      <Modal
        open={isExpanded}
        onClose={() => setIsExpanded(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90vw",
            maxHeight: "90vh",
            overflowY: "auto",
            p: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
            <IconButton onClick={() => setIsExpanded(false)}>
              <FullscreenExitIcon />
            </IconButton>
          </Box>
          {tableContent}
        </Paper>
      </Modal>
    </>
  );
};
