import React from "react";
import { Grid, Typography } from "@mui/material";
import Loader from "../components/common/Loader";
import { VariablesRankingBanditListing } from "./VariableRankingBanditListing";
import { useRankingBandit } from "./context/RankingBanditContext";
import { RankingBanditInfo } from "./RankingBanditInfo";
import { VarationsRankingBanditTable } from "./VarationsRankingBanditTable";

const RankingBandit: React.FC<any> = () => {
  const { flagFetched } = useRankingBandit();

  if (!flagFetched) return <Loader />;

  return (
    <Grid container alignItems="left" flexDirection="column">
      <Grid item>
        <RankingBanditInfo />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">Variables</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Layouts</Typography>
        </Grid>

        <Grid item xs={6}>
          <VariablesRankingBanditListing />
        </Grid>
        <Grid item xs={6}>
          <VarationsRankingBanditTable />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RankingBandit;
