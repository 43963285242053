import React from "react";
import { Grid, TextField, Box, Typography } from "@mui/material";
import CustomCxsTeaser from "../fields/CustomCxsTeaser";
import CustomCxsNsTeaser from "../fields/CustomCxsNsTeaser";
import CustomCxsSiteWideBanner from "../fields/CustomCxsSiteWideBanner";
import CustomCxsSwbJson from "../fields/CustomCxsSwbJson";
import CustomCxsBanner from "../fields/CustomCxsBanner";
import BooleanField from "../fields/BooleanField";
import DatepickerField from "../fields/DatepickerField";
import JsonField from "../fields/JsonField";
import AiText from "../fields/AiText";
import AndroidIosSupport from "../fields/AndroidIosSupport";

interface VariableTarget {
  id: number | string;
  var_key: string;
  type: string;
  sort_order: number | null;
  default_value: string;
}

interface Variable {
  value: string;
}

interface VariableIndexes {
  variIndex: number;
  flagVariIndex: number;
  flagVariId: string;
  baseIndex: number;
}

interface Config {
  user: {
    level: number;
  };
}

interface RenderVariableFieldProps {
  variableTarget: VariableTarget;
  variable: Variable;
  indexes: VariableIndexes;
  onChangeVariables: (
    e: React.ChangeEvent<HTMLInputElement>,
    indexes: VariableIndexes
  ) => void;
  editDisabled: boolean;
  stateStatus: number;
  config: Config;
  ruleData: any;
}

export const cxsCustomLabels: Record<string, string> = {
  // RPV
  discount: "Discount",
  discountRedPrice: "Discount red price",
  discountWhitePrice: "Discount white price",
  discountYellowPrice: "Also discount yellow price",
  excludeByArticle: "Exclude by article-IDs",
  excludeByBrand: "Exclude by brand",
  excludeByMarketingMarker: "Exclude by marketing marker",
  excludeMemberArticle: "Exclude all member articles",

  // PDP Messages
  countdownText: "Countdown text",
  dealMessage: "Deal message",
  exclude_article_ids: "Exclude article-IDs",
  expiresAt: "Expires at",
  link: "Link",
  textColor: "Text color",
  visibility_member_price: "Exclude all member articles",
  visibility_not_sale: "Display on white price",
  visibility_sale: "Display on red price",

  trackingPromotionCreative: "Tracking promotion creative",
  teaserTrackingPromotionCreative: "Tracking promotion creative",
  bannerTrackingPromotionCreative: "Tracking promotion creative",
  text: "Text",
};

const shouldSkipTracking = (varKey: string): boolean =>
  varKey === "teaserTrackingPromotionCreative" ||
  varKey === "bannerTrackingPromotionCreative";

const isEditDisabledForUser = (
  config: Config,
  stateStatus: number,
  varKey?: string
): boolean => {
  const isHighLevelUser = config.user.level === 25 || config.user.level === 100;
  const isCountdownOrDeal = varKey
    ? /countdownText|dealMessage/.test(varKey)
    : false;

  return isHighLevelUser && isCountdownOrDeal ? stateStatus > 30 : false;
};

const VariableTextField = ({
  varKey,
  value,
  label,
  onChange,
  indexes,
  disabled,
}: {
  varKey: string;
  value: string;
  label: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    indexes: VariableIndexes
  ) => void;
  indexes: VariableIndexes;
  disabled: boolean;
}) => (
  <div key={varKey}>
    <TextField
      margin="dense"
      value={value || ""}
      name={varKey}
      label={label}
      type="text"
      fullWidth
      size="small"
      onChange={(
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      ) => onChange(e as React.ChangeEvent<HTMLInputElement>, indexes)}
      disabled={disabled}
    />
  </div>
);

// Special case for link field with AndroidIosSupport
const LinkFieldWithSupport = ({
  varKey,
  value,
  label,
  onChange,
  indexes,
  disabled,
}: {
  varKey: string;
  value: string;
  label: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    indexes: VariableIndexes
  ) => void;
  indexes: VariableIndexes;
  disabled: boolean;
}) => (
  <Grid container key={`${varKey}-10`}>
    <Grid item xs={10}>
      <VariableTextField
        varKey={varKey}
        value={value}
        label={label}
        onChange={onChange}
        indexes={indexes}
        disabled={disabled}
      />
    </Grid>
    <Grid
      item
      xs={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <AndroidIosSupport />
    </Grid>
  </Grid>
);

export const renderVariableField = ({
  variableTarget,
  variable,
  indexes,
  onChangeVariables,
  editDisabled,
  stateStatus,
  config,
  ruleData,
}: RenderVariableFieldProps): React.ReactElement | null => {
  const customLabel =
    cxsCustomLabels[variableTarget.var_key] ?? variableTarget.var_key;

  if (shouldSkipTracking(variableTarget.var_key)) {
    return null;
  }

  if (variableTarget.type === "custom_csx_banner") {
    return (
      <CustomCxsBanner
        key={variableTarget.id}
        onChangeVariables={onChangeVariables}
        indexes={indexes}
        variable={variable}
      />
    );
  }

  if (variableTarget.var_key === "excludeMemberArticle") {
    return (
      <>
        <Grid container>
          <Grid item xs={12}>
            <Box mb={1} mt={1}>
              <Typography variant="h6">Exclusions</Typography>
              <Typography variant="body2">
                An excluded article will have none of the discounts above
                applied.
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <BooleanField
          key={variableTarget.id}
          onChangeVariables={onChangeVariables}
          label={customLabel}
          indexes={indexes}
          variable={variable}
          disabled={editDisabled}
        />
      </>
    );
  }
  if (variableTarget.type === "custom_csx_teaser") {
    return (
      <CustomCxsTeaser
        key={variableTarget.id}
        onChangeVariables={onChangeVariables}
        indexes={indexes}
        variable={variable}
      />
    );
  }

  // This gets overwritten in TrackingPromotionCreativeField.tsx
  if (
    (variableTarget.id === 181 &&
      variableTarget.var_key === "trackingPromotionCreative") ||
    (variableTarget.id === 262 &&
      variableTarget.var_key === "trackingPromotionCreative")
  ) {
    return null;
  }

  if (variableTarget.type === "custom_csx_ns_teaser") {
    return (
      <CustomCxsNsTeaser
        key={variableTarget.id}
        onChangeVariables={onChangeVariables}
        indexes={indexes}
        variable={variable}
      />
    );
  }

  if (variableTarget.type === "custom_csx_side_wide_banner") {
    return (
      <CustomCxsSiteWideBanner
        key={variableTarget.id}
        onChangeVariables={onChangeVariables}
        indexes={indexes}
        variable={variable}
      />
    );
  }

  if (variableTarget.var_key === "fullSiteWideBannerApps") {
    return (
      <CustomCxsSwbJson
        key={variableTarget.id}
        onChangeVariables={onChangeVariables}
        label={customLabel}
        indexes={indexes}
        variable={variable}
      />
    );
  }

  if (variableTarget.type === "json") {
    return (
      <JsonField
        key={variableTarget.id}
        onChangeVariables={onChangeVariables}
        label={customLabel}
        indexes={indexes}
        variable={variable}
      />
    );
  }

  if (
    variableTarget.type === "boolean" ||
    variableTarget.type === "textColor"
  ) {
    return (
      <BooleanField
        key={variableTarget.id}
        onChangeVariables={onChangeVariables}
        label={customLabel}
        indexes={indexes}
        variable={variable}
        disabled={editDisabled}
      />
    );
  }

  if (variableTarget.type === "datepicker") {
    return (
      <DatepickerField
        key={variableTarget.id}
        onChangeVariables={onChangeVariables}
        label={customLabel}
        indexes={indexes}
        variable={variable}
        disabled={
          config.user.level === 25 || config.user.level === 100
            ? stateStatus > 30
            : editDisabled
        }
      />
    );
  }

  if (variableTarget.type === "aitext") {
    return (
      <AiText
        key={variableTarget.id}
        onChangeVariables={onChangeVariables}
        label={customLabel}
        indexes={indexes}
        variable={variable}
        ruleData={ruleData}
      />
    );
  }

  if (variableTarget.var_key === "link") {
    return (
      <LinkFieldWithSupport
        varKey={variableTarget.var_key}
        value={variable?.value}
        label={customLabel}
        onChange={onChangeVariables}
        indexes={indexes}
        disabled={
          isEditDisabledForUser(config, stateStatus, variableTarget.var_key) ||
          editDisabled
        }
      />
    );
  }

  return (
    <VariableTextField
      varKey={variableTarget.var_key}
      value={variable.value !== undefined ? variable.value : ""}
      label={"variant..."}
      onChange={onChangeVariables}
      indexes={indexes}
      disabled={
        isEditDisabledForUser(config, stateStatus, variableTarget.var_key) ||
        editDisabled
      }
    />
  );
};
