import React from "react";
import { Grid, IconButton, Box, Paper } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import { renderVariableField } from "./utils/rankingBanditVariationFieldUtils";
import { VariableValue } from "./types";
import { isDuplicateValue } from "./utils/rankingBanditValidates";
import { useRankingBandit } from "./context/RankingBanditContext";
import { useConfig } from "../context";

export const VariablesRankingBanditListing: React.FC = () => {
  const {
    flagVariables,
    variableValues,
    handleAddValue,
    handleValueChange,
    handleDeleteValue,
    editDisabled,
    stateStatus,
    ruleData,
  } = useRankingBandit();
  const { config } = useConfig();

  return (
    <Grid item xs={12}>
      {flagVariables?.map((variable: any, variIndex: number) => {
        const variableTarget = flagVariables.find(
          (el: any) => el.id === variable.id
        );
        if (!variableTarget) return null;

        const fieldData = {
          variableTarget,
          variable,
          indexes: {
            variIndex,
            flagVariIndex: variIndex,
            flagVariId: variable.id,
            baseIndex: 0,
          },
        };

        return (
          <Paper sx={{ p: 2, width: "100%" }} key={variable.id}>
            <Grid container key={variable.id}>
              <Grid container item xs={12}>
                <Grid item xs={10}>
                  {variableTarget?.var_key}
                </Grid>
                <Grid item xs={2}>
                  <IconButton
                    onClick={() => handleAddValue(variable.var_key)}
                    disabled={editDisabled}
                  >
                    <AddBoxIcon fontSize="small" />
                  </IconButton>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                {variableValues[variable.var_key]?.map(
                  (valueObj: VariableValue) => (
                    <Box
                      key={valueObj.id}
                      sx={{ mb: 1, display: "flex", alignItems: "center" }}
                    >
                      <Box
                        sx={{
                          flexGrow: 1,
                          "& .MuiTextField-root": {
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: isDuplicateValue(
                                  variable.var_key,
                                  valueObj.value,
                                  variableValues
                                )
                                  ? "red"
                                  : undefined,
                              },
                              "&:hover fieldset": {
                                borderColor:
                                  isDuplicateValue(
                                    variable.var_key,
                                    valueObj.value,
                                    variableValues
                                  ) || valueObj.value.length < 1
                                    ? "red"
                                    : undefined,
                              },
                            },
                          },
                        }}
                      >
                        {renderVariableField({
                          ...fieldData,
                          variable: { value: valueObj.value },
                          onChangeVariables: (
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            handleValueChange(
                              variable.var_key,
                              valueObj.id,
                              e.target.value
                            );
                          },
                          editDisabled: editDisabled,
                          stateStatus,
                          ruleData,
                          config,
                        })}
                      </Box>
                      <IconButton
                        onClick={() =>
                          handleDeleteValue(variable.var_key, valueObj.id)
                        }
                        size="small"
                        disabled={
                          variableValues[variable.var_key]?.length <= 1 ||
                          editDisabled
                        }
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  )
                )}
              </Grid>
            </Grid>
          </Paper>
        );
      })}
    </Grid>
  );
};
