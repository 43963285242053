import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";

import { FetchRule } from "../helpers/FetchData";

import UpdateRuleData from "./UpdateRule";
import UpdateMab from "./UpdateMab";
import UpdateRollout from "./UpdateRollout";
import UpdateRankingBandit from "./UpdateRankingBandit";

import { Box } from "@mui/material";

const FlagViewRule: React.FC<{}> = () => {
  const { ruleId } = useParams();

  const [fullRuleData, setFullRuleData] = useState({ archived: 0, type: "" });
  const [ruleCallBack, setRuleCallBack] = useState("");

  const [loadingRuleStatus, setLoadingRuleStatus] = useState({
    status: 100,
    message: "Loading...",
  });

  const sortObject = (object: any) => {
    return Object.keys(object)
      .sort()
      .reduce((objEntries: any, key: any) => {
        objEntries[key] = object[key];
        return objEntries;
      }, {});
  };

  const sortMetricsFilter = useCallback((data: any) => {
    const metrics = [...data.metrics];
    data.metrics = metrics.map((m: any) => {
      if (!m.filter) {
        return m;
      } else {
        const mFilter = m.filter.map(sortObject);
        m.filter = mFilter;
        return m;
      }
    });
    return data;
  }, []);

  useEffect(() => {
    FetchRule((output: any) => {
      const orderedData = sortMetricsFilter(output.response[0]);
      setFullRuleData(orderedData);
      setLoadingRuleStatus({ status: output.status, message: output.message });
    }, Number(ruleId));
  }, [ruleId, sortMetricsFilter, ruleCallBack]);

  const updateAbtest = () => {
    return (
      <UpdateRuleData
        fullRuleData={fullRuleData}
        initData={JSON.stringify(fullRuleData)}
        updateParent={setRuleCallBack}
      />
    );
  };

  const updateMab = () => {
    return (
      <UpdateMab
        fullRuleData={fullRuleData}
        initData={JSON.stringify(fullRuleData)}
        updateParent={setRuleCallBack}
      />
    );
  };
  const updateRankingBandit = () => {
    return (
      <UpdateRankingBandit
        fullRuleData={fullRuleData}
        initData={JSON.stringify(fullRuleData)}
        updateParent={setRuleCallBack}
      />
    );
  };

  const updateRollout = () => {
    return (
      <UpdateRollout
        fullRuleData={fullRuleData}
        initData={JSON.stringify(fullRuleData)}
        updateParent={setRuleCallBack}
      />
    );
  };

  if (loadingRuleStatus.status === 200) {
    return (
      <Box sx={{ p: 2 }}>
        {fullRuleData.type === "mab"
          ? updateMab()
          : fullRuleData.type === "rollout"
            ? updateRollout()
            : fullRuleData.type === "ranking_bandit"
              ? updateRankingBandit()
              : updateAbtest()}
      </Box>
    );
  } else {
    return <>{loadingRuleStatus.message}</>;
  }
};

export default FlagViewRule;
