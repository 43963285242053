import { VariableValues } from "../types";

export const isDuplicateValue = (
  varKey: string,
  value: any,
  variableValues: VariableValues
) => {
  const values = variableValues[varKey]?.map((v) => v.value);
  return values?.filter((v) => v === value).length > 1;
};
